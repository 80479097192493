<template>
    <div>
        <div class="mt-3 mb-10 vx-row">
            <header class="w-1/2 vx-col">
                <h3 class="text-xl font-medium text-black">{{ $t("resorts.webcams.list") }}</h3>
                <p class="text-base">{{ $t("resorts.webcams.listadded") }}</p>
            </header>
            <div class="w-1/2 pt-2 text-right vx-col">
                <vs-button 
                    class="rounded-lg xs:w-full sm:w-auto" 
                    :to="{ name: 'edit-webcam', params: { 'webcamUuid': '', 'webcam': {}} }" >
                    {{ $t("resorts.webcams.addwebcam") }}
                </vs-button>
            </div>
        </div>
        <div>
            <Datatable 
                :config="datatableConfig"
                :elements="webcams"
                :stripe="true"
                :draggable="true"
                :sst="true"
                :pagination="pagination"
                @action="action"
                @sort="sort"
                @filter="filter"
                @checkbox="publish"
            />
        </div>
    </div>
</template>

<script>

import WebcamService from '@/modules/Resorts/Services/WebcamService'
import Datatable from '@/modules/Shared/Components/table/SKDataTable.vue'
import PaginationService from '@/modules/Shared/Services/PaginationService'

export default {
    name: 'webcams',
    components: {
        Datatable
    },
    data () {
        return {
            resortUuid: '',
            webcams: [],
            datatableConfig: [
                { 'type': 'link', 'field': 'url', head: this.$t("resorts.webcams.url"), 'searchable': false, 'sortable': false },
                { 'type': 'text', 'field': 'name', head: this.$t("resorts.webcams.name"), 'searchable': false, 'sortable': false },
                { 'type': 'check', 'field': 'published', head: this.$t("resorts.webcams.visibility"), 'searchable': false, 'sortable': false },
                { 'type': 'text', 'field': 'timeofyear', head: this.$t("timeofyear"), 'searchable': false, 'sortable': false },
                { 'type': 'text', 'field': 'type', head: this.$t("resorts.webcams.type"), 'searchable': false, 'sortable': false },
                { 'type': 'image', 'field': 'thumbnail_url', head: this.$t("resorts.webcams.preview"), 'searchable': false, 'sortable': false },
                { 'type': 'actions', 'field': 'actions', head: this.$t("resorts.webcams.actions"), 'searchable': false, 'sortable': false }
            ],
            pagination: {},
        }
    },
    async created() {
        this.resortUuid = this.$route.params.uuid
        this.getWebcams({})
    },
    methods: {
        async publish(uuid, value, field) {
            await WebcamService.saveWebcam(this.resortUuid, uuid, { [field]: value })
        },
        sort(webcamsOrdered) {
            WebcamService.sortWebcams(this.resortUuid, webcamsOrdered)
        },
        async getWebcams(parameters) {
            this.webcams = await WebcamService.getWebcams(this.resortUuid, parameters)
            this.pagination = {
                per_page: this.webcams.per_page,
                total: this.webcams.total
            }
            this.webcams = this.webcams.data.map(
                webcam => ({...webcam, 'actions': [ 'delete', 'edit' ]})
            )
        },
        filter(params) {
            this.getWebcams(PaginationService.parseParameters(params))
        },
        action(type, uuid) {
            const webcam = this.webcams.filter(webcam => webcam.uuid === uuid)[0];
            switch(type) {
                case 'edit':   
                    this.$router.push({
                        name: 'edit-webcam',
                        params: {
                            'webcamUuid': webcam.uuid,
                            'webcam': webcam
                        }
                    });
                break;
                case 'delete':
                    this.deleteWebcam(uuid)
                break;
            }
        },
        async deleteWebcam(uuid) {
            this.$vs.loading()
            let response = await WebcamService.deleteWebcam(this.resortUuid, uuid)
            
            if(response.code == 200) {
                this.getWebcams({})
                this.$vs.notify( { text: this.$t("resorts.webcams.removed.ok"), color: 'success', position:'top-right' } )
            } else {
                this.$vs.notify( { text: this.$t("resorts.webcams.removed.ko"), color: 'danger', position:'top-right'} )
            }

            this.$vs.loading.close();
        }
    }
}
</script>
