<template>
    <div>
        <div class="mt-3 mb-10 vx-row">
            <header class="w-1/2 vx-col">
                <h3 class="text-xl font-medium text-black">{{ $t("resorts.zones.list") }}</h3>
                <p class="text-base">{{ $t("resorts.zones.listadded") }}</p>
            </header>
            <div class="w-1/2 pt-2 text-right vx-col">
                <vs-button
                    class="rounded-lg xs:w-full sm:w-auto"
                    :to="{ name: 'create-zone' }" >
                    {{ $t("shared.add") }}
                </vs-button>
            </div>
        </div>
        <div>
            <Datatable
                :config="datatableConfig"
                :elements="zones"
                :stripe="true"
                :draggable="true"
                :sst="true"
                :pagination="pagination"
                @action="action"
                @sort="sort"
            />
        </div>
    </div>
</template>

<script>

import ZonesService from '@/modules/Resorts/Services/ZonesService'
import Datatable from '@/modules/Shared/Components/table/SKDataTable.vue'
import loader from "@/modules/Shared/Mixins/loader";

export default {
    name: 'zones',
    mixins: [loader],
    components: {
        Datatable
    },
    data () {
        return {
            resortUuid: '',
            zones: [],
            datatableConfig: [
                { 'type': 'text', 'field': 'name', head: this.$t("resorts.webcams.name"), 'searchable': false, 'sortable': false },
                { 'type': 'actions', 'field': 'actions', head: this.$t("resorts.webcams.actions"), 'searchable': false, 'sortable': false }
            ],
            pagination: {},
        }
    },
    async created() {
        this.resortUuid = this.$route.params.uuid
        await this.getZones({})
    },
    methods: {
        async sort(sortedZones) {
            await ZonesService.sortZones(this.resortUuid, sortedZones)
        },
        async getZones(parameters) {
            this.zones = await ZonesService.getZones(this.resortUuid, parameters)
            this.pagination = {
                per_page: this.zones.per_page,
                total: this.zones.total
            }

            this.zones = this.zones.map(
                zone => ({...zone, 'actions': [ 'delete', 'edit' ]})
            )
        },
        action(type, uuid) {
            switch(type) {
                case 'edit':
                    this.$router.push({
                        name: 'edit-zone',
                        params: {
                            'zoneUuid': uuid
                        }
                    });
                break;
                case 'delete':
                    this.deleteZone(uuid)
                break;
            }
        },
        async deleteZone(uuid) {
            await this.loadAndNotify(async () => {
                await ZonesService.deleteZone(this.resortUuid, uuid)
                await this.getZones({})
            }, false)
        }
    }
}
</script>
